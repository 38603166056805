import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage, FormattedHTMLMessage,injectIntl, Link } from "gatsby-plugin-intl"
import Layout from "../components/layout";

import Posts from "../components/posts";
import Pagination from "../components/pagination";
import SEO from "../components/seo/seo";
import TwFrame from "../components/Style/TwFrame"

const PostsPage = ({  data, pageContext,intl }) => {
	//const intl = useIntl();

	return (
		<Layout>
			<SEO title={intl.formatMessage({ id: "Templates.Posts.headline", defaultMessage:"Headline"  })} />
			
			<h1 className="tw-text-4xl tw-text-center tw-mb-10 tw-font-semibold tw-font-heading">	
			
				<FormattedMessage id="Templates.Posts.headline" defaultMessage="Headline" />
			</h1>
			
			
			<Posts
				posts={data.allMdx.edges}
				defaultAuthor={data.site.siteMetadata}
			/>
			<Pagination
				pagesUrl="/posts"
				numPages={pageContext.numPages}
				currPage={pageContext.currentPage}
			/>
			
			
		</Layout>
	);
};

export const query = graphql`
	query($skip: Int!, $limit: Int!, $lang: String!) {
		allMdx(
			limit: $limit
			skip: $skip
			sort: { fields: frontmatter___date, order: DESC }
			filter: {
				fields: { slug: { regex: "/posts/" } }
				frontmatter: { lang: { eq: $lang } }
			}
		) {
			edges {
				node {
					frontmatter {
						title
						date
						tags
						author
						authorUrl
					}
					excerpt
					id
					timeToRead
					fields {
						slug
						path
					}
				}
			}
		}
		site {
			siteMetadata {
				author
				authorUrl
			}
		}
	}
`;

export default injectIntl(PostsPage);